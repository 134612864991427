import { List } from "./components/List";
import EnterCode from "./components/EnterCode";

const AppRoutes = [
    {
        index: true,
        element: <EnterCode />
    },
    {
        path: ':junk',
        index: true,
        element: <EnterCode />
    },
    {
        path: 'list/:code',
        index: true,
        element: <List />
    }
];

export default AppRoutes;
