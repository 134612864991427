import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import './enter-code.css'

function EnterCode() {
    const [code, setCode] = useState('');
    const [valid, setValid] = useState(false);
    const navigate = useNavigate();

    const codeChanged = async (event) => {
        const thecode = event.target.value.toUpperCase().trim();
        setCode(thecode);
        setValid(thecode.length > 0);
    }

    const gotoApp = () => {
        localStorage.setItem('tooable-code', code);
        navigate('/list/' + code);
    }

    useEffect(() => {
        const code = localStorage.getItem('tooable-code');
        if (code) {
            navigate('/list/' + code);
        }        
    }, []);

    return (
        <div className="code">
            <div className="box">
                <div className="input">
                    <label htmlFor="codeInput" className="form-label">Enter Code</label>
                    <input type="text" className={`form-control ${ valid ? 'is-valid' : '' }`} id="codeInput" value={code} onChange={codeChanged} />
                </div>
                
                <div className="continue-btn">
                    <Button disabled={!valid} onClick={gotoApp} color={valid ? 'primary' : 'secondary'}>Continue</Button>
                </div>
            </div>
        </div>
    );
}

EnterCode.propTypes = {}

export default EnterCode;