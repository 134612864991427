import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ListGroup, ListGroupItem, Input, Button, Form } from 'reactstrap'
import { Gear, XLg, Trash } from 'react-bootstrap-icons';

export function List(props) {
    const [newItem, setNewItem] = useState('');
    const [items, setItems] = useState([]);
    const [edit, setEdit] = useState(false);
    const { code } = useParams();
    const navigate = useNavigate();
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    if (code) {
        headers.code = code;
    }        

    const getItems = async () => {
        const response = await fetch('api/todoitems', {
            headers
        });
        const data = await response.json();
        setItems(data);
    }

    const addNew = async (e) => {
        e.preventDefault();
        setNewItem('');
        if (!newItem) return;
        const body = { title: newItem };
        const response = await fetch('api/todoitems/create', {
            method: 'POST',
            headers,
            body: JSON.stringify(body)
        });
        const data = await response.json();
        setItems([...items, data]);
    }

    const updateItem = async (item) => {
        item.complete = !item.complete;
        setItems([...items]);
        await fetch('api/todoitems/update', {
            method: 'POST',
            headers,
            body: JSON.stringify(item)
        });
    }

    const deleteItem = async (item) => {
        const response = await fetch(`api/todoitems/${item.id}`, {
            method: 'DELETE',
            headers
        });
        const data = await response.json();
        if (data === item.id) {
            setItems((items) => items.filter(i => i.id !== item.id))
        }
    }

    const validNewItem = () => {
        return !!newItem.trim();
    }

    const logout = () => {
        localStorage.removeItem('tooable-code');
        navigate('/');
    }

    useEffect(() => {
        document.addEventListener("visibilitychange", async () => {
            if (!document.hidden) {
                await getItems();
            }
        });
        async function loadData() {
            await getItems();
        }
        loadData()
    }, []);

    return (
        <div>
            <div className="top-area">
                <span className="icon">
                    {edit && (
                        <XLg onClick={() => setEdit((value) => !value)} />
                    )}
                    {!edit && (
                        <Gear onClick={() => setEdit((value) => !value)} />
                    )}
                </span>
            </div>
            <ListGroup>
                {items.map((item, index) => (
                    <ListGroupItem key={`item-${index}`} color={item.complete ? 'success' : 'default' }>
                        <div className="list-item">
                            <span className={item.complete ? 'complete' : 'pending'}>{item.title}</span>
                            {edit && (
                                <div className="trash">
                                    <Trash onClick={() => deleteItem(item)} />
                                </div>
                            )}
                            {!edit && (
                                <Input type="checkbox" checked={item.complete} onChange={() => updateItem(item)} />
                            )}
                        </div>
                    </ListGroupItem>
                ))}
            </ListGroup>
            {edit && (
                <div className="new-item">
                    <Form onSubmit={(e) => addNew(e)} className="new-form">
                        <Input placeholder="New item" value={newItem} onChange={(e) => setNewItem(e.target.value)} />
                        <Button color="primary" disabled={!validNewItem()} outline type="submit">Add</Button>
                    </Form>
                </div>
            )}
            {edit && (
                <div className="bottom-actions">
                    <span className="code-display">{code}</span>
                    <Button color="primary" outline onClick={logout}>Logout</Button>
                </div>
            )}
        </div>
    );
}
