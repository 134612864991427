import React from 'react';
import { Container } from 'reactstrap';

export function Layout(props) {

    return (
        <Container>
            {props.children}
        </Container>
);
}
